/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code",
    h2: "h2",
    pre: "pre",
    strong: "strong",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The easiest and most flexible way of rendering fancy PDFs is using HTML+CSS as your source. ", React.createElement(_components.a, {
    href: "https://github.com/mileszs/wicked_pdf"
  }, "wicked_pdf"), " and ", React.createElement(_components.a, {
    href: "https://github.com/pdfkit/pdfkit"
  }, "pdfkit"), ", the most popular Rails gems, employ ", React.createElement(_components.code, null, "wkhtmltopdf"), " under the hood to generate them. It seems that there are multiple options of running ", React.createElement(_components.code, null, "wkhtmltopdf"), " on Heroku. This post will explore them, analyze their drawbacks and identify the most reliable one."), "\n", React.createElement(_components.h2, null, "Method 1: Apt package"), "\n", React.createElement(_components.p, null, "First thing that comes to mind is installing ", React.createElement(_components.code, null, "wkhtmltopdf"), " package from Ubuntu Apt repositories. The easiest way of doing this is using ", React.createElement(_components.code, null, "apt"), " buildpack (provided by Heroku):"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "heroku buildpacks:add --index 1 heroku-community/apt\n")), "\n", React.createElement(_components.p, null, "Next, create ", React.createElement(_components.code, null, "Aptfile"), " file in your project's root directory with the following content:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "wkthmltopdf\n")), "\n", React.createElement(_components.p, null, "Finally, push the code to Heroku."), "\n", React.createElement(_components.p, null, "The build phase will list all dependencies required to install ", React.createElement(_components.code, null, "wkhtmltopdf"), ". The package depends on audio libraries, low-level USB libraries (!), X11 and GTK libs, etc. Once the deployment completes, we can test our setup:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "heroku run bash -a YOURAPPNAME\n")), "\n", React.createElement(_components.p, null, "Once the one-off dyno boots up, run:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "$ wkhtmltopdf https://www.ruby-lang.org/en/ ruby.pdf\nThis application failed to start because it could not find or load the Qt platform plugin \"xcb\"\nin \"\".\n\nReinstalling the application may fix this problem.\nAborted\n")), "\n", React.createElement(_components.p, null, "Even though the Apt package downloaded so many dependencies, it was still not enough to successfully run ", React.createElement(_components.code, null, "wkhtmltopdf"), "."), "\n", React.createElement(_components.p, null, "Even if this solution worked, it would be extremely inefficient: the application's slug size increased by 146 MB. It's crucial to keep slug size small as it affects not only deployment time but also time required to boot up any dyno (resources defined in ", React.createElement(_components.code, null, "Procfile"), ", one-off dynos with ", React.createElement(_components.code, null, "rails console"), " etc.)."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "TL;DR: apt package prohibitively increases bundle size and still doesn't work. Avoid.")), "\n", React.createElement(_components.h2, null, "Method 2: buildpack"), "\n", React.createElement(_components.p, null, "This option provides ", React.createElement(_components.code, null, "wkhtmltopdf"), " binary as a buildpack. There are a few different buildpacks around, the most\npopular (and up-to-date) being ", React.createElement(_components.a, {
    href: "https://github.com/dscout/wkhtmltopdf-buildpack"
  }, "dscout/wkhtmltopdf-buildpack"), ". It's important to note that the latest version of ", React.createElement(_components.code, null, "wkhtmltopdf"), " is ", React.createElement(_components.code, null, "0.12.6"), ", while this buildpack offers at most ", React.createElement(_components.code, null, "0.12.4"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "heroku buildpacks:add https://github.com/dscout/wkhtmltopdf-buildpack.git\nheroku config:set WKHTMLTOPDF_VERSION=\"0.12.4\"\n")), "\n", React.createElement(_components.p, null, "This option increases slug size by 55.6 MB."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note for ", React.createElement(_components.code, null, "heroku-18"), " / ", React.createElement(_components.code, null, "heroku-20"), " stack users"), ": if your HTML source references external resources fetched over https, they will not appear on the PDF due to SSL error caused by a missing library. To resolve this issue, add ", React.createElement(_components.code, null, "apt"), " buildpack and add the following package to your ", React.createElement(_components.code, null, "Aptfile"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "libssl1.0.0\n")), "\n", React.createElement(_components.p, null, "In total, using ", React.createElement(_components.code, null, "wkhtmltopdf"), " buildpack with ", React.createElement(_components.code, null, "libssl"), " increases the bundle size by 56.9 MB. This increase is passable, but not perfect. The buildpack also includes ", React.createElement(_components.code, null, "wkhtmltoimage"), " binary. If you don't need it, you're still going to have it in your app slug, unnecessarily increasing its size."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "TL;DR: fast and reliable method, with moderate slug size increase; lacks the latest version.")), "\n", React.createElement(_components.h2, null, "Method 3: Ruby gem bundling the wkhtmltopdf binary"), "\n", React.createElement(_components.p, null, "There are three popular options:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubygems.org/gems/wkhtmltopdf-binary"
  }, "wkhtmltopdf-binary"), " — provides version 0.12.6 for macOS, Linux\n32bit and Linux 64bit. Not recommended for Heroku: the gem bundles three binaries and we need only one. Each binary is about 40 MB so the slug size would increase significantly. Fine for development."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubygems.org/gems/wkhtmltopdf-binary-edge"
  }, "wkhtmltopdf-binary-edge"), " — offers the latest version (0.12.6)\nbut like the previous option, contains three binaries. Even the author ", React.createElement(_components.a, {
    href: "https://github.com/pallymore/wkhtmltopdf-binary-edge#if-you-are-using-wkhtmltopdf-on-heroku-please-use-this-gem-instead"
  }, "recommends something else for Heroku"), ". Good for development."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubygems.org/gems/wkhtmltopdf-heroku"
  }, "wkhtmltopdf-heroku"), " — offers a single Linux binary suitable for running on Heroku."), "\n"), "\n", React.createElement(_components.p, null, "Your ", React.createElement(_components.code, null, "Gemfile"), " could look like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "group :production do\n  gem 'wkhtmltopdf-heroku'\nend\n\ngroup :development do\n  gem 'wkhtmltopdf-binary-edge'\nend\n")), "\n", React.createElement(_components.p, null, "If your application is deployed on ", React.createElement(_components.code, null, "heroku-18"), " stack, you'll also need to add ", React.createElement(_components.code, null, "libssl1.0.0"), " to your ", React.createElement(_components.code, null, "Aptfile"), " to be able to fetch external resources over https."), "\n", React.createElement(_components.p, null, "This method appears to be extremely size-efficient: slug size increased only by 14 MB (15.9 MB with ", React.createElement(_components.code, null, "libssl"), ")."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "TL;DR: Reliable and size-efficient way offering the latest version. Recommended.")), "\n", React.createElement(_components.h2, null, "Note about binary files security"), "\n", React.createElement(_components.p, null, "Pay particular attention when dealing with any externally-downloaded binaries. We can assume that packages downloaded from Apt repository are adequately verified. What about the buildpack? We can check in its ", React.createElement(_components.code, null, "compile"), " phase that it downloads the files from ", React.createElement(_components.a, {
    href: "https://github.com/dscout/wkhtmltopdf-buildpack/blob/6cfdcbd7dd9c3cb6ddf0d4b97bb79be874007b29/bin/compile#L21"
  }, "official releases section on GitHub"), ". That is a trusted way to download the official binary."), "\n", React.createElement(_components.p, null, "Finally, the gems. They merely bundle the binaries but provide no proof that they were not maliciously modified. While you should always be grateful to open source developers for their contributions, it's always a good idea to verify that the binary files are the originals provided by wkhtmltopdf maintainers. Hint: ", React.createElement(_components.a, {
    href: "https://wkhtmltopdf.org/downloads.html"
  }, "download"), " proper .deb file (xenial for heroku-16, bionic for heroku-18), extract the package and make sure (using ", React.createElement(_components.code, null, "diff"), " or at least ", React.createElement(_components.code, null, "openssl dgst -sha256"), ") that the binaries are identical."), "\n", React.createElement(_components.h2, null, "Summary"), "\n", React.createElement(_components.p, null, "We evaluated different options of installing ", React.createElement(_components.code, null, "wkhtmltopdf"), " on Heroku for your Ruby apps. Using ", React.createElement(_components.code, null, "wkhtmltopdf-heroku"), " gem is the most efficient option. Before you deploy any bundled binary to production, make sure to verify that it's the original binary provided by the ", React.createElement(_components.code, null, "wkhtmltopdf"), " maintainers."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
